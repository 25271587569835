<template>
  <div class="page-content">
    <section class="sheet padding-10mm" v-if="reservation">
    <div class="row margin-bottom-40">
      <div class="col-xs-6">
        <img style="border:0.1mm solid #ddd;" class="ticket-logo" src="../assets/logo_rivieranuragica_v2.jpg">
      </div>
      <div class="col-xs-6">
        <div class="barcode-container">
          <img id="ticket-barcode" class="barcode-top" :src="'https://barcode.tec-it.com/barcode.ashx?data=' + reservation.ticket_code + '&code=Code128&multiplebarcodes=true&translate-esc=true&unit=Fit&dpi=96&imagetype=Gif&rotation=90&color=%23000000&bgcolor=%23ffffff&codepage=&qunit=Mm&quiet=0'">
        </div>
      </div>
    </div>
    <div class="row margin-bottom-30">
      <div class="col-xs-6">
        <h4>Organizzatore</h4>
        <p><b>Geseco Surl</b></p>
        <p>Viale Costa Smeralda, 38<br/>Arzachena (SS)</p>
        <p>Telefono: +39 345 7200094<br/>Email: archeologia@gesecoarzachena.it</p>
      </div>
      <div class="col-xs-6">
        <h4>Cliente</h4>
        <p><b><span id="ticket_customer_first_name">{{StringsHelper.getStringOrNA(reservation.customer_first_name)}}</span> <span id="ticket_customer_last_name">{{StringsHelper.getStringOrNA(reservation.customer_last_name)}}</span></b></p>
        <p><span id="ticket_customer_email">{{StringsHelper.getStringOrNA(reservation.customer_email)}}</span><br/><span id="ticket_customer_phone">{{StringsHelper.getStringOrNA(reservation.customer_phone)}}</span></p>
        <p><span id="ticket_customer_nationality">{{StringsHelper.getStringCountryFromISO(reservation.customer_nationality)}}</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div style="border:0.1mm solid #ddd;padding:15px;">
          <h4>I visitatori sono gentilmente pregati di:</h4>
          <ul>
            <li>Indossare mascherina e tenere la distinza minima di 1 metro dagli altri visitatori.</li>
            <li>Non fumare.</li>
            <li>Non rimuovere nessun oggetto all'interno dell'area archeologica.</li>
            <li>Non arrampicarsi e non camminare sulle strutture.</li>
            <li>Non circolare in costume da bagno e/o a torso nudo.</li>
            <li>Non consumare cibi e bevande all'interno dell'area.</li>
            <li>Tenere i cani al guinzaglio.</li>
            <li>Non utilizzare droni e altri dispositivi al di fuori di macchine fotografiche e apparecchi di registrazione audio/video. Tenere i minori di 12 anni accompagnati da un adulto.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ticket-footer">
      <table class="table table-bordered">
        <tbody>
        <tr>
          <td class="bold">Offerta</td>
          <td class="bold" colspan="3"><span id="ticket_product_list">
            <span id="product_list" v-for="(product, index) in reservation.product_list" :key="product">
              {{StringsHelper.getProduct(product)}}<span v-if="!(index===(reservation.product_list.length-1))">,</span>
            </span>
          </span></td>
          <td style="width:40mm;vertical-align: middle;" rowspan="5"><img id="ticket-qr-code" :src="'https://api.qrserver.com/v1/create-qr-code/?size=180x180&data=' + reservation.ticket_code"></td>
        </tr>
        <tr>
          <td class="bold">Tariffa</td>
          <td colspan="3"><span id="ticket_rate_quantity_list">
            <span v-for="(rate, index) in reservation.rate_list" :key="rate">
              {{rate.quantity}} x {{StringsHelper.getRate(rate.rate)}} <br v-if="!(index===(reservation.rate_list.length-1))" />
            </span>
          </span></td>
        </tr>
        <tr>
          <td class="bold" nowrap>Data ingresso</td>
          <td><span id="ticket_date">{{Utils.getShortDateStringFromISO(reservation.date)}}</span> <span id="ticket_hour">{{Utils.getHourStringFromISO(reservation.date)}}</span></td>
          <td class="bold" nowrap>Valido fino al</td>
          <td><span id="ticket_validity_date">{{Utils.getShortDateStringFromISO(ticket_validity_date)}}</span></td>
        </tr>
        <tr>
          <td class="bold" nowrap>Data acquisto</td>
          <td><span id="ticket_created_date">{{Utils.getDateStringFromISO(reservation.purchase_date)}}</span></td>
          <td class="bold">Importo</td>
          <td>€<span id="ticket_price">{{StringsHelper.getStringOrNA(reservation.price_final)}}</span></td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
  </div>
</template>

<script>
import {getReservationDetail} from "../api";
import baseMixin from "../common/baseMixin";
import moment from 'moment'

export default {
  name: "Ticket",
  mixins: [baseMixin],
  created() {
    document.body.classList.add("A4");
  },
  beforeDestroy() {
    document.body.classList.remove("A4");
  },
  data: () => {
    return {
      reservation: null,
      reservation_id: null,
      ticket_validity_date: new Date().toISOString()
    }
  },
  mounted(){
    this.reservation_id = this.$route.params.reservation_id
    this.getReservationDetail(this.reservation_id)
    window.addEventListener('afterprint', () => window.close());

  },
  methods: {
    async getReservationDetail(id) {
      try {
        this.reservation = await getReservationDetail(id);
        this.ticket_validity_date = moment(this.reservation.date).add(7, 'day')
        window.print()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>
@page { size: A4 }
a[href]:after {
  content: none !important;
}
.hide-print {
  display: none!important;
}
.display-print {
  display: inline-block!important;
}
#cash_report .right-column,
#analytics .right-column {
  width: 100%;
}
#cash_report .page-content,
#analytics .page-content {
  padding: 0px;
  border: none;
}
#cash_report #today-date {
  display: inline-block!important;
}
.A4 .sheet {
  width: 210mm!important;
  height: 287mm!important;
}
</style>
